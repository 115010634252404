import Slider from "react-slick";
import pixadLatestPub from '../../../../assets/imgs/pixad-latest1.png'
import pixadLatestAdv from '../../../../assets/imgs/pixad-latest2.png'
import admaticLatestPub from '../../../../assets/imgs/admatic-latest1.png'
import admaticLatestAdv from '../../../../assets/imgs/admatic-latest2.png'
import { useContext, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import Wrap from '../../../../components/wrap'
import { AliasContext } from "../../../../components/context/AliasContext";

export default function LatestNews() {

    const alias = useContext(AliasContext)

    const isMobile = useMediaQuery({
        query: '(max-width: 768px)',
    });

    let slider = useRef(null)

    const renderArrows = () => {
        return (
            <div className="latest-slider-dots">
                <button onClick={() => slider.slickPrev()}>
                    <svg width={18} height={16} fill="currentColor" viewBox="0 0 448 512">
                        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>
                </button>
                <button onClick={() => slider.slickNext()}>
                <svg width={18} height={16} fill="currentColor" viewBox="0 0 448 512">
                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
                </svg>
                </button>
            </div>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };
    return (
        <section className={`latest-news-section ${alias}`}>
            <Wrap>
                <div>
                    <h2 className="section-title white">Partnerships</h2>
                    <p className="section-desc white">We facilitate brand prominence through creative and effective campaigns, connecting advertisers with a broad network of publishers to reach target audiences seamlessly.</p>
                </div>
                <div>
                    {renderArrows()}
                    <Slider
                        ref={c => (slider = c)} {...settings}>
                        <div className="slider-wrapper">
                            <div>
                                <img src={alias === "admatic" ? admaticLatestPub : pixadLatestPub} />
                            </div>
                            <div className="text-wrapper">
                                <h3>Advertisers</h3>
                                <p>{alias === "admatic" ? 'AdMatic' : 'Pixad'} offers advertisers the opportunity to reach their target audience more accurately, while providing publishers with the chance to increase their advertising revenues.</p>
                                <Link to="/advertisers" className={`button-primary ${alias}`}>READ MORE</Link>
                            </div>
                        </div>
                        <div className="slider-wrapper">
                            <div>
                                <img src={alias === "admatic" ? admaticLatestAdv : pixadLatestAdv} />
                            </div>
                            <div className="text-wrapper">
                                <h3>Publishers</h3>
                                <p>The {alias === "admatic" ? 'AdMatic' : 'Pixad'} panel displays data in real-time. Our technology, developed with artificial intelligence, publishes safe advertisements that protect advertisers and also boost revenues for publishers' websites.</p>
                                <Link to="/publishers" className={`button-secondary ${alias}`}>READ MORE</Link>
                            </div>
                        </div>
                        <div className="slider-wrapper">
                            <div>
                                <img src={alias === "admatic" ? admaticLatestPub : pixadLatestPub} />
                            </div>
                            <div className="text-wrapper">
                                <h3>Advertisers</h3>
                                <p>{alias === "admatic" ? 'AdMatic' : 'Pixad'} offers advertisers the opportunity to reach their target audience more accurately, while providing publishers with the chance to increase their advertising revenues.</p>
                                <Link to="/advertisers" className={`button-primary ${alias}`}>READ MORE</Link>
                            </div>
                        </div>
                        <div className="slider-wrapper">
                            <div>
                                <img src={alias === "admatic" ? admaticLatestAdv : pixadLatestAdv} />
                            </div>
                            <div className="text-wrapper">
                                <h3>Publishers</h3>
                                <p>The {alias === "admatic" ? 'AdMatic' : 'Pixad'} panel displays data in real-time. Our technology, developed with artificial intelligence, publishes safe advertisements that protect advertisers and also boost revenues for publishers' websites.</p>
                                <Link to="/publishers" className={`button-secondary ${alias}`}>READ MORE</Link>
                            </div>
                        </div>
                    </Slider>
                </div>
            </Wrap>
        </section>
    )
}