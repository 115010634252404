import { Link } from 'react-router-dom'
import Wrap from '../../../components/wrap'
import { useContext } from 'react'
import { AliasContext } from '../../../components/context/AliasContext'

export default function Platform() {

    const alias = useContext(AliasContext)

    return (
        <section className="platform-description-section bg-platform-advertisers">
            <Wrap>
                <div>
                    <h2 className="section-title"><span className={`${alias === "admatic" ? 'text-admatic-primary' : 'text-pixad-primary'}`}>{alias === "admatic" ? 'AdMatic' : 'Pixad'}</span> is a platform that offers advertising solutions in all advertising spaces for content creator websites and applications.</h2>
                    <p className="section-desc text-black">Ad inventory optimization: {alias === "admatic" ? 'AdMatic' : 'Pixad'} helps publishers maximize the value of their ad inventory. {alias === "admatic" ? 'AdMatic' : 'Pixad'} includes automatic bid optimization and ad placement strategies to fill ad spaces with the most lucrative advertisements.</p>
                </div>
            </Wrap>
        </section>
    )
}