import { useContext } from "react"
import { AliasContext } from "../../../components/context/AliasContext"

export default function Header() {

    const alias = useContext(AliasContext)

    return (
        <div className="in-page__hero">
            <div>
                <div className={`page-bg ${alias === "admatic" ? 'after:bg-admatic-publishers-hero' : 'after:bg-pixad-publishers-hero'} `}>
                    <h1 className="hero__title">Publishers</h1>
                    <p className="section-desc white">The {alias === "admatic" ? 'AdMatic' : 'Pixad'} panel displays data in real-time. Our technology, developed with artificial intelligence, publishes safe advertisements that protect advertisers and also boost revenues for publishers' websites.</p>
                </div>
            </div>
        </div>
    )
}