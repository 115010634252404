import Wrap from "../../../components/wrap";
import admaticIntegration from "../../../assets/imgs/admatic-integration.jpg"
import pixadIntegration from "../../../assets/imgs/pixad-integration.jpg"
import { useContext } from "react";
import { AliasContext } from "../../../components/context/AliasContext";

export default function Integration() {

    const alias = useContext(AliasContext)

    return (
        <section className="integration-section">
            <Wrap>
                <h2 className="section-title">Detailed Reporting System</h2>
                <p className="section-desc text-black">Allows you to monitor estimated earnings, updating them in 60-minute intervals. Through {alias === "admatic" ? 'AdMatic' : 'Pixad'} reports, you can assess which ad formats or placements are performing best and which are generating more revenue, enabling you to track and optimize your ad inventory.</p>
                <img src={alias === "admatic" ? admaticIntegration : pixadIntegration} />
            </Wrap>
        </section>
    )
}
