import Wrap from "../../../components/wrap";
import admaticIntegration from "../../../assets/imgs/admatic-integration.jpg"
import pixadIntegration from "../../../assets/imgs/pixad-integration.jpg"
import { useContext } from "react";
import { AliasContext } from "../../../components/context/AliasContext";

export default function Integration() {

    const alias = useContext(AliasContext)

    return (
        <section className="integration-section">
            <Wrap>
                <h2 className="section-title">Multiple Integration</h2>
                <p className="section-desc text-black">Offers publishers the opportunity for integration in various options through Script Code, Prebid Client/S2S, Open RTB, and Mobile Application SDKs.</p>
                <img src={alias === "admatic" ? admaticIntegration : pixadIntegration} />
            </Wrap>
        </section>
    )
}
