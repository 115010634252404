import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AliasContext } from '../../../../components/context/AliasContext';

export default function Hero() {

    const alias = useContext(AliasContext)

    const isMobile = useMediaQuery({
        query: '(max-width: 1024px)',
    });

    return (
        <section className={`hero__section ${alias}`}>
            <div>
                <h1 className="hero__title">Optimized Advertising</h1>
                <div className="hero-buttons">
                    <Link to="/advertisers" className={`button-primary ${alias}`}><span>FOR ADVERTISERS</span></Link>
                    <Link to="/publishers" className={`button-secondary ${alias}`}><span>FOR PUBLISHERS</span></Link>
                </div>
            </div>
        </section>
    )
}