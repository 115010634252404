import ContactHeader from './components/header'
import ContactForm from './components/contact-form'
import Informations from './components/informations'
import { useContext } from 'react'
import { AliasContext } from '../../components/context/AliasContext'
import { Helmet } from 'react-helmet'

export default function Contact() {

    const alias = useContext(AliasContext)

    return (
        <>
            <Helmet><title>{alias === "admatic" ? 'AdMatic' : 'Pixad'} - Contact</title></Helmet>
            <ContactHeader alias={alias}/>

            <div className="contact-content">
                <ContactForm alias={alias} />
                <Informations alias={alias} />
            </div>
        </>
    )
}