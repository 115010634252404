import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { AliasContext } from "./components/context/AliasContext";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';
import Loading from "./components/loading";

function App({history}) {

  let host;
  const [alias, setAlias] = useState(null)
  

  useEffect(() => {
    host = window.location.host

    if (host.includes("admatic") || host.includes("localhost")) {
      setAlias("admatic")
      //ReactGA.initialize('G-4VB831D7X5');
    } else if (host.includes("pixad")) {
      setAlias("pixad")
      //ReactGA.initialize('G-H97Z2CF1WF');
    }
  }, [alias])

  if (alias == null) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Helmet>
          <title>Loading...</title>
        </Helmet>
        <Loading />
      </div>
    )
  }

  return (
    <AliasContext.Provider value={alias}>
      <Helmet>
        <title>{alias === "admatic" ? 'AdMatic' : 'Pixad'}</title>
        <meta name="description" content={alias === "admatic" ? 'AdMatic is a creative powerhouse!' : 'Pixad is a creative powerhouse!'} />
        <link rel="icon" href={alias === "admatic" ? 'admatic-favicon.png' : 'pixad-favicon.svg'} />
      </Helmet>
      <RouterProvider router={router} />
    </AliasContext.Provider>
  );
}

export default App;
