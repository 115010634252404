import { useContext, useState } from 'react'
import logo from '../../assets/imgs/logo.svg'
import admaticLogo from '../../assets/imgs/admatic.svg'
import admaticLogoLight from '../../assets/imgs/admatic-logo-light.png'
import logoLight from '../../assets/imgs/logo-light.svg'
import { useMediaQuery } from 'react-responsive'
import { Link, NavLink } from 'react-router-dom'
import { menu } from '../../utils/consts'
import { AliasContext } from '../../components/context/AliasContext'

export default function Header({ style = 'primary', location }) {

    const alias = useContext(AliasContext)

    const isMobile = useMediaQuery({
        query: '(max-width: 1024px)',
    });

    const [status, setStatus] = useState(false);
    const renderLogo = () => {
        if (alias === "admatic") {
            return isMobile ? admaticLogo : (style === 'primary' ? admaticLogo : admaticLogoLight)
        } else {
            return isMobile ? logo : (style === 'primary' ? logo : logoLight)
        }
    }

    return (
        <header className={`header ${isMobile ? 'primary' : style} ${alias}`}>
            <div>
                <button className="nav-bar-btn" onClick={() => setStatus(prevStatus => !prevStatus)}>
                    <span className={`${isMobile && (status && 'open')}`}></span>
                </button>
                <Link to="/" className="header__logo" onClick={() => setStatus(false)}>
                    <img src={renderLogo()} alt={alias === "admatic" ? 'AdMatic' : 'Pixad'} />
                </Link>
                <nav className={`nav__menu ${isMobile ? (status ? 'show' : '') : ''}`}>
                    <ul>
                        {
                            menu.length > 0 &&
                            menu.map((item, index) => (
                                <li key={index}><NavLink onClick={() => setStatus(prevStatus => !prevStatus)} to={item?.path}>{item?.title}</NavLink></li>
                            ))
                        }
                        {
                            (isMobile && alias !== "admatic") &&
                            <>
                                <li><NavLink onClick={() => setStatus(prevStatus => !prevStatus)} to={alias === 'admatic' ? 'https://apps.admatic.com.tr/auth/sign-up' : 'https://apps.pixad.com.tr/auth/sign-up'}>CREATE ACCOUNT</NavLink></li>
                                <li><NavLink onClick={() => setStatus(prevStatus => !prevStatus)} to={alias === 'admatic' ? 'https://apps.admatic.com.tr/' : 'https://apps.pixad.com.tr/'}>LOGIN</NavLink></li>
                            </>
                        }
                    </ul>
                </nav>
            </div>
            <div className="sub__nav">
                <div className="sub__nav-actions">
                    {alias !== "admatic" &&
                        <>
                        <Link className={`action-button-fill ${location.pathname === '/publishers' ? 'primary' : style} ${alias}`} target="_blank" to={alias === 'admatic' ? 'https://apps.admatic.com.tr/auth/sign-up' : 'https://apps.pixad.com.tr/auth/sign-up'}>CREATE ACCOUNT</Link>
                    <Link className={`action-button-normal ${style}`} target="_blank" to={alias === 'admatic' ? 'https://apps.admatic.com.tr/' : 'https://apps.pixad.com.tr/'}>LOGIN</Link>
                    </>}
                </div>
            </div>
        </header>
    )
}